import { NextSeo } from "next-seo";
import AppLayout from "../components/layouts/AppLayout";
import AuthCheck from "../components/Auth/AuthCheck";
import Button from "../components/Atoms/Button";

import profitario_logo from "../../public/assets/services/logos/LogoProfitario.png";
import bankstatementwizard_logo from "../../public/assets/services/logos/LogoBankStatementWizard.png";
import Image from "next/image";
import Link from "next/link";

export default function Home() {
  return (
    <>
      <NextSeo title="Hub" description={""} canonical={`https://saritra.com`} />
      <div className="p-4">
        <h1 className="text-2xl font-semibold text-gray-800">
          Welcome to the Saritra Hub
        </h1>
        <p className="text-gray-600">Please select a service to continue</p>
      </div>
    </>
  );
}

Home.getLayout = function getLayout(page) {
  return <AppLayout>{page}</AppLayout>;
};
